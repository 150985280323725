<template>
	<section class="recommendations">
		<h4>Books that I bought, read & liked</h4>
		<p>About my work (Coding & Product)</p>
		<div class="recommendation">
			<a href="https://amzn.to/3muITIK" target="blank">
				<img class="item" src="../../assets/clean-code.jpg" alt="Clean Code Book">
				<div class="item-text">
					<h5>Clean Code</h5>
					<h5>Robert C. Martin</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/3BmDDLt" target="blank">
				<img class="item" src="../../assets/clean-coder.jpg" alt="The Clean Coder book">
				<div class="item-text">
					<h5>The Clean Coder</h5>
					<h5>Robert C. Martin</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/3l4wKYk" target="blank">
				<img class="item" src="../../assets/awesome.jpg" alt="Obviously awesome book">
				<div class="item-text">
					<h5>Obviously awesome</h5>
					<h5>April Dunford</h5>
				</div>
			</a>
		</div>
		<p>About Life & Society</p>
		<div class="recommendation">
			<a href="https://amzn.to/3sR9alt" target="blank">
				<img class="item" src="../../assets/factfulness.jpg" alt="Factfulness book">
				<div class="item-text">
					<h5>Factfulness</h5>
					<h5>Hans Rosling</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/3yfqz8E" target="blank">
				<img class="item" src="../../assets/monk.jpg" alt="The monk who sold his ferrari book">
				<div class="item-text">
					<h5>The monk who sold his ferrari</h5>
					<h5>Robin Sharma</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/2X50agW" target="blank">
				<img class="item" src="../../assets/pig.jpg" alt="Pequeño cerdo capitalista book">
				<div class="item-text">
					<h5>Pequeño cerdo capitalista</h5>
					<h5>Sofía Macías</h5>
				</div>
			</a>
		</div>
		<h4>Some tools that I use daily</h4>
		<div class="recommendation">
			<a href="https://amzn.to/3gwHhKx" target="blank">
				<img class="item" src="../../assets/sony.jpg" alt="Sony headphones">
				<div class="item-text">
					<h5>Sony WH-XB900N</h5>
					<h5>Wireless headphones</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/2XKniBu" target="blank">
				<img class="item" src="../../assets/mx-master.jpg" alt="Logitech MX Master 2s Mouse">
				<div class="item-text">
					<h5>Logitech MX Master 2s</h5>
					<h5>Wireless mouse</h5>
				</div>
			</a>
		</div>
		<div class="recommendation">
			<a href="https://amzn.to/3B7lscm" target="blank">
				<img class="item" src="../../assets/laptop.jpg" alt="Amazon Laptop stand">
				<div class="item-text">
					<h5>Amazon Basics</h5>
					<h5>Laptop stand</h5>
				</div>
			</a>
		</div>
	</section>
</template>

<style src="./RecommendationsComponent.scss" lang="scss">
</style>