<template>
	<section class="experiences">
		<a
			class="cv"
			href="CV-Borja-Rodrigo.pdf"
			download="CV Borja"
		>
			<p>&#128196; Click here to download my CV</p>
		</a>
		<div class="experience">
			<h4>TL;DR</h4>
			<p class="text">
				&#129299; On <b>2018</b>, with 27 years old, I finished my Degree on <b>Computer Engineering</b> (UPV).
			</p>
			<p class="text">
				👨‍💻That same year, I joined <b>Epigram Technologies</b>, my first
				job as a Web Developer.
				<br>
				<sub>9k/year</sub>
			</p>
			<p class="text">
				&#128118; In <b>2019</b> I joined <b>Jeff</b> as a Junior Frontend Developer.
				<br>
				<sub>16k/year</sub>
			</p>
			<p class="text">
				&#128567; <b>2020</b>, coronavirus arrived! Company was selling retail franchises, so I got fired.
			</p>
			<p class="text">
				A couple months later, still in <b>2020</b>, I joined <b>Voicemod</b> as Frontend Developer.
				<br>
				<sub>24k/year &rarr; 28k/year &rarr; 30k/year &rarr; 35k/year &rarr; 38k/year &rarr; 43k/year</sub>
			</p>
			<p class="text">That's all so far!</p>
		</div>
		<br>
		<div class="thoughts">
			<h4>What my colleagues think of me:</h4>
			<p class="text-thoughts">
				<b>Agustin Carmona, Engineering Manager @ Voicemod:</b>
				<br><br>
				Always with positive comments and constructive attitude towards his teammates.
				He offers to help whenever is possible, even between teams.
				He's in continuous learning, asking workmates and reading on his own.
				You can see that he enjoys his job.
			</p>
			<hr>
			<p class="text-thoughts">
				<b>Luis Martín del Olmo, QA Engineer @ Voicemod:</b>
				<br><br>
				Borja is always willing to help to tackle any possible issue that I find that
				it's not his immediate responsibility so that shows an incredible proactivity
				in trying to get a better product.
				He is always my go-to man to check any front-end, accounts or web functionality,
				so he is vital for the group.
			</p>
			<hr>
			<p class="text-thoughts">
				<b>Jesús Rodriguez, Engineering Managers Guild Lead @ Voicemod:</b>
				<br><br>
				Your mood is excellent. Despite not agreeing with everything, you tried
				your best to move on. You have been involved in helping other peers and teams,
				and trying to coordinate some developments.
			</p>
			<hr>
			<p class="text-thoughts">
				<b>Luis Miguel Gil, DevOps Engineer @ Voicemod:</b>
				<br><br>
				I think you are a very versatile worker, and I can see that's the kind of worker
				that can adapt to every situation. You are not only a programmer, you take one
				more step and take care, and I think that's what Voicemod values want about us.
				You are always trying to enforce good practices and every time we needed to work
				together it was so nice to share this time with you. You want to be autonomous
				and you always ask why we are doing thing like we are doing and taking ownership,
				and that's awesome.
			</p>
			<hr>
			<p class="text-thoughts">
				<b>Andrea Belluccia, Engineering Manager @ Voicemod:</b>
				<br><br>
				You've demonstrated that you are always available to help others. You can take
				jobs that involve responsibility and finish them. Due to the time and
				involvement you had in the company, you have knowledge about almost everything
				concerning our team. You are always keen to unblock your work and the work of others.
			</p>
		</div>
	</section>
</template>

<style src="./ExperienceComponent.scss" lang="scss">
</style>