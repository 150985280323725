<template>
	<section class="investments">
		<h4>WHERE & HOW have I invested my money?</h4>
		<p>- <b>ALL</b> my investments are <b>long-term</b> (+5 yrs). They are split between <b>Index Funds</b>, <b>Cryptocurrencies</b> and <b>Stocks</b>.</p>
		<p>- The <b>largest amount</b> since 2021 is on <b><a href="https://indexacapital.com/t/OswDJF">Indexa Capital</a></b>, a platform that auto-invests on index funds for the best profit.</p>
		<p>- I <b>bought</b> my first <b>BTCs</b> and <b>mined</b> a couple milion <b>DOGE</b> in <b>2013</b>, but I'm <b>not rich</b> for being too greedy.</p>
		<p>- Right now, I have my BTC in <b><a href="https://blockfi.com/?ref=dc0c1262">BlockFi</a></b> where I recieve <b>interests</b> and <b><a href="https://www.coinbase.com/join/marti_0va">Coinbase</a></b> to make purchases once in a while.</p>
		<p>- <b><a href="https://www.degiro.es/amigo-invita-amigo/empezar-a-invertir.html?id=DDC5B48B&utm_source=mgm">DEGIRO</a></b> is the platform I use for buying <b>stocks</b>. I try to get stocks from <b>companies</b> that I <b>like</b>.</p>
	</section>
</template>

<style src="./InvestmentsComponent.scss" lang="scss">
</style>